import React from 'react'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type ReicipientType = {
  firstname: string
  lastname: string
  imageUrl?: string
}

type ActionType = {
  title?: string
  onClick: () => void
}

export type RecipientViewProps = {
  title?: string
  sender: ReicipientType
  primaryAction?: ActionType
  secondaryAction?: ActionType
}

export const RecipientView = (props: RecipientViewProps) => {
  const { title, sender, primaryAction, secondaryAction } = props
  const fontFamily = 'Montserrat, sans-serif'

  const senderStyles = sender.imageUrl
    ? {
        aspectRatio: '1/1',
        width: '100%',
        maxWidth: '44px',
        maxHeight: '44px',
        backgroundImage: `url(${sender.imageUrl})`,
        backgroundPostition: 'center',
        backgroundSize: 'cover',
        backgrounRepeat: 'no-repeat',
        borderRadius: '50px',
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        color: '#fff',
        backgroundColor: '#7C7C7C',
        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
        fontSize: '20px',
        width: '44px',
        height: '44px',
      }

  const actionSharedStyles = {
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',
    flexDirection: 'row' as const,
    columnGap: '6px',
  }

  const recipientInitals = `${sender.firstname[0] + sender.lastname[0]}`

  return (
    <Flex flexDirection="column" rowGap="x2" width="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column" alignItems="flex-start">
          <Text
            type="body"
            content={title}
            style={{
              color: '#404040',
              fontSize: '16px',
              fontWeight: 700,
              fontFamily: fontFamily,
            }}
          />

          <Text
            type="body"
            content="FROM"
            style={{
              color: '#B7B7B7',
              fontSize: '8px',
              fontWeight: 700,
              fontFamily: fontFamily,
              lineHeight: 1.2,
            }}
          />
          <Text
            type="body"
            content={`${sender.firstname} ${sender.lastname}`}
            style={{
              color: '#6B7280',
              fontSize: '12px',
              fontWeight: 500,
              fontFamily: fontFamily,
              lineHeight: 1.2,
            }}
          />
        </Flex>
        <Div style={{ ...senderStyles }}>
          {!sender.imageUrl && recipientInitals}
        </Div>
      </Flex>
      <Flex flexDirection="row" columnGap="x1" width="100%">
        <Div
          onClick={primaryAction?.onClick}
          style={{
            ...actionSharedStyles,
            color: '#fff',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
            backgroundColor: '#444',
            width: '100%',
            fontSize: '16px',
          }}
        >
          <Icon size={18} name="stars" />
          {primaryAction?.title}
        </Div>
        <Div
          onClick={secondaryAction?.onClick}
          style={{
            ...actionSharedStyles,
            background:
              'linear-gradient(90deg, #A168E9 -20.58%, #D67FFF 117.96%)',
          }}
        >
          <Icon size={18} name="print_request" color="#fff" />
          {secondaryAction?.title}
        </Div>
      </Flex>
    </Flex>
  )
}
