import React from 'react'
import styles from './ExclusiveOfferScreen.module.scss'
import {
  Flex,
  Input,
  Text,
  Transition,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { useUpdateAccount } from 'src/react_query'
import {
  useActions,
  useDebounce,
  useEmailValidation,
  useState,
} from 'src/hooks'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

const ExclusiveOfferScreen = (props: {
  onFinishedJoin: () => void
  onClose: () => void
}) => {
  const { onFinishedJoin, onClose } = props
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [updateAccountFailureMessage, setUpdateAccountFailureMessage] =
    useState<string>()

  const debouncedEmail = useDebounce(email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const handleUsernameChange = (value: string) => {
    setUsername(sanitizeUsername(value))
  }
  const debouncedUsername = useDebounce(username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      if (
        !usernameValidation.isValid ||
        usernameValidation.isLoading ||
        !emailValidation.isValid ||
        emailValidation.isLoading
      ) {
        return
      }
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({
        account: {
          username,
          password,
          email,
        },
        joinReward: '3daytrial',
      })
      actions.updatedAccount(updatedAccount)
      onFinishedJoin()
    } catch (error) {
      setUpdateAccountFailureMessage(
        error?.toString() ?? 'Failed to update account.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Transition title={'Creating your Account'} isLoading={isLoading} />
  }

  return (
    <div className={styles.backgroundContainer}>
      <div
        style={{
          position: 'absolute',
          top: 20,
          height: '50%',
          width: '100%',
          backgroundImage: `url(https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/PremiumCardCoversGrid.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <div className={styles.container}>
        <Flex flexDirection={'row'} width={'100%'} justifyContent={'end'}>
          {/* <Button
            title="Login"
            type="primary"
            size="xSmall"
            fontWeight="semiBold"
            textColorOverride="black"
            backgroundColorOverride="white"
            onClick={() => {}}
          /> */}
          <button className={styles.closeButton} onClick={() => onClose()}>
            <Icon name={'cancel'} color="black" size={12} />
          </button>
        </Flex>

        <div className={styles.offerCard}>
          <div className={styles.cardImageContainer}>
            <img
              className={styles.cardImageContainer.img}
              src={
                'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/GoodVibes3dCard.png'
              }
              alt="GoodVibesCardFrontPanel"
            />
            <Flex
              flexDirection={'row'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              style={{ marginTop: '12px' }}
            >
              <Text
                type={'body'}
                content={'Free Trial'}
                style={{ marginRight: '4px' }}
                color={'primaryHeading'}
                weight="bold"
              />
              <Icon name={'premium_crown'} color="#FECD39" />
            </Flex>
          </div>
          <Text
            type={'subtitle'}
            content={'Exclusive Offer'}
            alignment="center"
            color={'primaryHeading'}
            weight="bold"
          />
          <Text type="caption" alignment="center" weight="semiBold">
            Finish setting up your account and get receive a Free 3 Day trial
            which includes <strong>3</strong> Digital and <strong>1</strong>{' '}
            Printed Greeting Card!
          </Text>

          <div className={styles.loginForm}>
            <VStack gap="x2_5">
              <Input
                icon="envelope"
                placeholder="Email"
                onChange={setEmail}
                value={email}
                type={'text'}
                message={
                  emailValidation.errorMessage && email
                    ? {
                        type: 'danger',
                        content: emailValidation.errorMessage,
                        icon: 'information',
                      }
                    : undefined
                }
                isFullWidth={true}
                isLoading={emailValidation.isLoading}
              />
              <Input
                icon="user"
                placeholder="Username"
                onChange={handleUsernameChange}
                value={username}
                type={'text'}
                message={
                  !usernameValidation.isValid &&
                  !!username &&
                  !usernameValidation.isLoading
                    ? { type: 'danger', content: 'Username is already taken.' }
                    : undefined
                }
                isFullWidth={true}
                isLoading={usernameValidation.isLoading}
              />
              <Input
                icon="lock"
                placeholder="Password"
                type="password"
                onChange={setPassword}
                value={password}
                message={
                  updateAccountFailureMessage
                    ? { type: 'danger', content: updateAccountFailureMessage }
                    : undefined
                }
                isFullWidth={true}
              />
            </VStack>
            <button
              className={styles.tryPremiumButton}
              onClick={() => onSubmit()}
            >
              <Icon name={'premium_crown'} color="#FECD39" />
              <Text
                type={'caption'}
                weight="semiBold"
                content={'Try Premium for Free'}
                style={{ marginLeft: '8px', color: 'white' }}
              />
            </button>
          </div>
        </div>

        <div className={styles.featuresSection}>
          <Flex
            flexDirection={'row'}
            width={'100%'}
            justifyContent={'start'}
            alignItems={'center'}
          >
            <Icon name={'premium_crown'} color="#FECD39" />
            <Text
              type={'caption'}
              content={'Unlock 1000+ Premium card designs'}
              style={{ marginLeft: '8px' }}
              color={'primaryHeading'}
              weight="regular"
            />
          </Flex>
          <Flex
            flexDirection={'row'}
            width={'100%'}
            justifyContent={'start'}
            alignItems={'center'}
          >
            <Icon name={'premium_crown'} color="#FECD39" />
            <Text
              type={'caption'}
              content={'Unlimited Digital Card Sends'}
              style={{ marginLeft: '8px' }}
              color={'primaryHeading'}
              weight="regular"
            />
          </Flex>
          <Flex
            flexDirection={'row'}
            width={'100%'}
            justifyContent={'start'}
            alignItems={'center'}
          >
            <Icon name={'premium_crown'} color="#FECD39" />
            <Text
              type={'caption'}
              content={'Design like a pro'}
              style={{ marginLeft: '8px' }}
              color={'primaryHeading'}
              weight="regular"
            />
          </Flex>
        </div>
        <p className={styles.viewMore} onClick={() => actions.openPricing()}>
          View More Plans
        </p>
        {/* <p className={styles.terms}>
          Cancel anytime. Renews automatically.{' '}
          <a href="https://www.sendoutcards.com/resources">Terms Apply.</a>
        </p> */}
      </div>
    </div>
  )
}

export default ExclusiveOfferScreen
